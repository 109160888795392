// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';

const ExternalRedirect = () => {
    useEffect(() => {
        window.location.href = 'https://stanbicasantehenegolf.com/';
    }, []);

    return null;
};

export default ExternalRedirect;
